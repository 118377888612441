import { Actions } from "../actions/types";
import update from "immutability-helper";

const initialState = {
  theme: "light",
  posts: [],
  post: {},
  categories: [],
  powerflow: [],
};

export const main = (state = initialState, { type, payload }) => {
  switch (type) {
    case Actions.GET_POSTS:
      return { ...state, posts: payload };

    case Actions.GET_POST:
      return { ...state, post: payload };

    case Actions.ADD_POST:
      return { ...state, post: payload };

    case Actions.UPDATE_POST:
      const update_post_index = state.posts.findIndex(
        (i) => i._id === payload._id
      );
      return update(state, { posts: { [update_post_index]: payload } });

    case Actions.DELETE_POST:
      return { ...state, posts: state.posts.filter((p) => p._id !== payload) };

    case Actions.GET_CATS:
      return { ...state, categories: payload };

    case Actions.FETCH_POWER_DATA:
      return { ...state, powerflow: payload };

    default:
      return state;
  }
};
