import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPost } from "../../actions/main";
import "./write.css";

export default function Write() {
  let data = useSelector((state) => state);
  const dispatch = useDispatch();

  const [postData, setData] = useState({
    title: null,
    desc: null,
    image: null,
    category: "Open Source",
  });

  const handleChange = ({ currentTarget: input }) => {
    let newData = { ...postData };
    newData[input.name] = input.value;
    setData(newData);
  };

  const handleImageChange = (e) => {
    let newData = { ...postData };
    newData["image"] = e.target.files[0];
    setData(newData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newPost = {
      ...postData,
      username: data.auth.user.username,
    };

    try {
      dispatch(addPost(newPost));
      window.location.replace("/");
    } catch (err) {
      console.log(err);
    }
  };

  //console.log(data.main);
  return (
    <div className="write">
      {postData.image && (
        <img
          className="writeImg"
          src={URL.createObjectURL(postData.image)}
          alt=""
        />
      )}
      <form className="writeForm" onSubmit={handleSubmit}>
        <div className="writeFormGroup">
          <label htmlFor="fileInput">
            <i className="writeIcon fas fa-plus"></i>
          </label>
          <select
            name="category"
            type="select"
            className=""
            onChange={(e) => handleChange(e)}
          >
            {data.main.categories.map((c) => (
              <option value={c.name} key={c.id}>
                {c.name}
              </option>
            ))}
          </select>
          <input
            type="file"
            id="fileInput"
            name="image"
            accept="image/jpeg,image/png,image/gif"
            style={{ display: "none" }}
            onChange={(e) => handleImageChange(e)}
          />
          <input
            type="text"
            placeholder="Title"
            name="title"
            className="writeInput"
            autoFocus={true}
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="writeFormGroup">
          <textarea
            placeholder="Tell your story..."
            name="desc"
            type="text"
            className="writeInput writeText"
            onChange={(e) => handleChange(e)}
          ></textarea>
        </div>
        <button className="writeSubmit" type="submit">
          Publish
        </button>
      </form>
    </div>
  );
}
