import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPosts } from "../../actions/main";
import Post from "../post/Post";
import "./posts.css";

export default function Posts({ posts }) {
  let data = useSelector((state) => state.main);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPosts());
  }, [dispatch]);

  return (
    <div className="posts">
      {data.posts.map((p, idx) => (
        <Post key={idx} post={p} />
      ))}
    </div>
  );
}
