import axios from "axios";
import { logout } from "../actions/auth";
import store from "../store";

const auth = axios.create({ baseURL: process.env.REACT_APP_AUTH_URL });

auth.interceptors.request.use(
  (req) => {
    //console.log(`${req.method} ${req.url}`);
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

auth.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.data.detail === "Token is invalid or expired"
    ) {
      console.log("Refresh" + error.response.data.detail + ", logging out.");
      store.dispatch(logout());
    }

    if (
      error.response.status === 403 &&
      error.response.data.detail ===
        "Authentication credentials were not provided."
    ) {
      console.log(error.response.data.detail + ", logging out.");
      store.dispatch(logout());
    }

    if (
      error.response.status === 403 &&
      error.response.data.detail === "Public key is not in the proper format"
    ) {
      console.log(error.response.data.detail + ", logging out.");
      store.dispatch(logout());
    }

    if (
      error.response.status === 403 &&
      error.response.data.detail ===
        "Token iss claim does not match the expected issuer"
    ) {
      console.log(error.response.data.detail + ", logging out.");
      store.dispatch(logout());
    }

    if (
      error.response.status === 403 &&
      error.response.data.detail === "Unable to decode token."
    ) {
      console.log(error.response.data.detail + ", logging out.");
      store.dispatch(logout());
    }

    return Promise.reject(error);
  }
);
// eslint-disable-next-line
export default { auth };
