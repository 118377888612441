import React, { useRef } from "react";
import { LoginStart, login, LoginFailure } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import "./login.css";

export default function Login() {
  let data = useSelector((state) => state);
  const userRef = useRef();
  const passwordRef = useRef();
  const dispatch = useDispatch();

  // const required = (value) => {
  //   if (!value) {
  //     return (
  //       <div className="alert alert-danger" role="alert">
  //         This field is required!
  //       </div>
  //     );
  //   }
  // };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(LoginStart());
    try {
      dispatch(login(userRef.current.value, passwordRef.current.value));
     } catch (err) {
      console.log(err);
        dispatch(LoginFailure());
      }
  };

  return (
    <div className="login">
      <span className="loginTitle">Login</span>
      <form className="loginForm" onSubmit={handleSubmit}>
        <label>Username</label>
        <input
          type="text"
          className="loginInput"
          placeholder="Enter your username..."
          ref={userRef}
        />
        <label>Password</label>
        <input
          type="password"
          className="loginInput"
          placeholder="Enter your password..."
          ref={passwordRef}
        />
        <button className="loginButton" type="submit" disabled={data.isFetching}>
          Login
        </button>
      </form>
    </div>
  );
}
