import { Actions } from "./types";
import Auth from "../services/Auth";

export const applyTheme = (theme) => async (dispatch) => {
  dispatch({ type: Actions.SET_THEME, payload: theme });
};

export const getAllPosts = () => async (dispatch) => {
  const response = await Auth.api.get("/posts/");
  dispatch({
    type: Actions.GET_POSTS,
    payload: response.data,
  });
};

export const getPost = (path) => async (dispatch) => {
  const response = await Auth.api.get(`/posts/${path}/`);
  dispatch({
    type: Actions.GET_POST,
    payload: response.data,
  });
};

export const addPost = (data) => async (dispatch) => {
  console.log(data);
  const response = await Auth.api.post("/posts/", data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

  if (response.status === 400) {
    console.log(response.data);
  } else {
    dispatch({
      type: Actions.ADD_POST,
      payload: data,
    });
  }
};

export const updatePost = (id, data) => async (dispatch) => {
  console.log(data);
  const response = await Auth.api.put(`/posts/${id}/`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  dispatch({
    type: Actions.UPDATE_POST,
    payload: response.data,
  });
};

export const deletePost = (id, user) => async (dispatch) => {
  const response = await Auth.api.delete(`/posts/${id}/`, {
    data: { username: user },
  });
  dispatch({
    type: Actions.DELETE_POST,
    payload: response.data,
  });
};

export const uploadImage = (data) => async (dispatch) => {
  await Auth.api.post("/images/", data);
};

export const getCats = () => async (dispatch) => {
  const response = await Auth.api.get("/categories/");
  dispatch({
    type: Actions.GET_CATS,
    payload: response.data,
  });
};

export const getPowerData = (hours) => async (dispatch) => {
  const response = await Auth.api.get(`/powerflow/?start_date=${hours}`);
  dispatch({ type: Actions.FETCH_POWER_DATA, payload: response.data });
};
