import React from "react";
import "./utils.css";
import Card from "../../components/card/Card";
import chart from "../../images/chart.png";

export default function Utils() {
  return (
    <div>
      <Card
        title="Power Statistics"
        imageUrl={chart}
        link=""
        target="/fronius"
      />
    </div>
  );
}
