import React from "react";
import "./socialfollow.css";

export default function SocialFollow() {
  return (
    <div className="social">
      <a
        href="https://www.instagram.com/moltenaluminium/"
        className="socialIcon"
      >
        <i className="socialIcon fab fa-instagram-square"></i>
      </a>
    </div>
  );
}
