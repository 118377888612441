import React from "react";
import "./card.css";
import { Link } from "react-router-dom";

export default function Card({ title, imageUrl, target }) {
  return (
    <Link to={target} className="link">
      <div className="card">
        <img className="cardImg" src={imageUrl} alt="" />
        <div className="cardTitle">{title}</div>
      </div>
    </Link>
  );
}
