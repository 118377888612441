import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPowerData } from "../../actions/main";
import "./fronius.css";
import "rc-slider/assets/index.css";
import Slider from "rc-slider";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

export default function Fronius() {
  let data = useSelector((state) => state);
  const now = new Date();
  const dispatch = useDispatch();
  const [calculatedDate, setDate] = useState(formatDateTime(now));
  const [hourIndicator, setHourIndication] = useState(1);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    dispatch(getPowerData(calculatedDate));
  }, [calculatedDate, dispatch]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  function formatDateTime(customDate) {
    const year = customDate.getFullYear();
    const month = String(customDate.getMonth() + 1).padStart(2, "0");
    const day = String(customDate.getDate()).padStart(2, "0");
    const hours = String(customDate.getHours()).padStart(2, "0");
    const minutes = String(customDate.getMinutes()).padStart(2, "0");
    const seconds = String(customDate.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  const calculatePastDate = (hoursAgo) => {
    setHourIndication(hoursAgo);
    var date = new Date();
    date.setHours(date.getHours() - hoursAgo);
    setDate(formatDateTime(date));
    return date;
  };

  //console.log(windowSize[0]);

  return (
    <>
      <p className="sliderLabel">Hours to display: {hourIndicator}</p>
      <Slider
        dots
        min={1}
        max={72}
        step={1}
        startPoint={hourIndicator}
        onChange={calculatePastDate}
        tipFormatter={calculatePastDate}
      />

      <LineChart width={windowSize[0]} height={600} data={data.main.powerflow}>
        <XAxis dataKey="timestamp" />
        <YAxis />
        <Tooltip />
        <Legend />
        <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
        <Line type="monotone" dataKey="p_pv" stroke="#00FF00" />
      </LineChart>
      <LineChart width={windowSize[0]} height={600} data={data.main.powerflow}>
        <XAxis dataKey="timestamp" />
        <YAxis />
        <Tooltip />
        <Legend />
        <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
        <Line type="monotone" dataKey="p_grid" stroke="#82249d" />
      </LineChart>
      <LineChart width={windowSize[0]} height={600} data={data.main.powerflow}>
        <XAxis dataKey="timestamp" />
        <YAxis />
        <Tooltip />
        <Legend />
        <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
        <Line type="monotone" dataKey="p_load" stroke="#a01212" />
      </LineChart>
    </>
  );
}
