import jwt_decode from "jwt-decode";
import authConnectionService from "./AuthConnectionService";
import store from "../store";

class AuthApiService {
  login(email, password) {
    return authConnectionService.auth
      .post("login/", { email, password })
      .then((response) => {
        if (response.data.access) {
          let user = this.getUserByToken(response.data.access);
          user.accessToken = response.data.access;
          user.refreshToken = response.data.refresh;
          return user;
        }
      });
  }

  logout() {
    localStorage.removeItem("store");
  }

  getUserByToken(accessToken) {
    var decoded = jwt_decode(accessToken);
    var jwt_exp = new Date(decoded.exp * 1000);

    console.debug("Token exp: " + jwt_exp);

    var user = {};
    user.uid = decoded.uid;
    user.username = decoded.username;
    user.firstname = decoded.firstname;
    user.lastname = decoded.lastname;
    user.email = decoded.email;
    user.profile_pic = decoded.profile_pic;

    return user;
  }

  getTokenExpiry(token) {
    var decoded = jwt_decode(token);
    var jwt_exp = new Date(decoded.exp * 1000);

    return jwt_exp;
  }

  isTokenExpired(token) {
    var decoded = jwt_decode(token);

    if (decoded.exp < Date.now() / 1000) {
      return true;
    } else {
      return false;
    }
  }

  async refreshAccessToken() {
    // Get current user from state
    let user = store.getState().auth.user;
    let refresh = user.refreshToken;

    console.log("Refreshing expired accessToken");

    const response = await authConnectionService.auth.post("login/refresh/", {
      refresh,
    });

    // Update the accessToken
    user.accessToken = response.data.access;

    //Return updated user object with new token
    return Promise.resolve(user);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthApiService();
