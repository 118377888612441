import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import store from "../store";
import authApiService from "./AuthApiService";
import { refresh, logout } from "../actions/auth";

// Obtain latest token from state each time the function is called
function getAccessToken() {
  let accessToken = store.getState().auth.user.accessToken;
  return accessToken;
}

// Function that will be called to refresh accessToken
const refreshAuthLogic = (failedRequest) => {
  return authApiService
    .refreshAccessToken()
    .then((user) => {
      // Update failed request header with new token for retry
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + user.accessToken;

      // Refresh the user state with new access token via Redux
      store.dispatch(refresh(user));
      return Promise.resolve();
    })
    .catch((error) => {
      console.log("Refresh failed");
      store.dispatch(logout);
      return Promise.reject(error);
    });
};

// Create axios instance for api
const api = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  headers: {
    "content-type": "application/json",
  },
});

// Attach the refresh interceptor to api axios instance
createAuthRefreshInterceptor(api, refreshAuthLogic, {
  statusCodes: [401, 403],
});

api.interceptors.request.use(
  (request) => {
    let user = store.getState().auth.user;
    if (user && user.accessToken) {
      request.headers["Authorization"] = "Bearer " + getAccessToken();
    }

    return request;
  },
  (error) => {
    console.log("Auth Error");
    return Promise.reject(error);
  }
);
// eslint-disable-next-line
export default { api };
