import { Actions } from "../actions/types";

let user = null;

if (localStorage.getItem("state")) {
  user = JSON.parse(localStorage.getItem("state")).auth.user;
} else {
  user = null;
}

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
      };
    case Actions.REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case Actions.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isFetching: false,
        user: action.payload.user,
      };
    case Actions.LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        isFetching: false,
        user: null,
      };
    case Actions.REFRESH_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload.user,
      };
    case Actions.REFRESH_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case Actions.LOGIN_START:
      return {
        ...state,
        user: null,
        isFetching: true,
        error: false,
      };
    case Actions.UPDATE_START:
      return {
        ...state,
        isFetching: true,
      };
    case Actions.UPDATE_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isFetching: false,
        error: false,
      };
    case Actions.UPDATE_FAILURE:
      return {
        ...state,
        user: state.user,
        isFetching: false,
        error: true,
      };
    case Actions.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        isFetching: false,
        user: null,
      };

    case Actions.SET_MESSAGE:
      return { message: action.payload };

    case Actions.CLEAR_MESSAGE:
      return { message: "" };
    default:
      return state;
  }
}
