import React, { useEffect, useState } from "react";
import "./topbar.css";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/auth";
import Switch from "react-switch";
import { applyTheme } from "../../actions/main";

export default function TopBar() {
  let data = useSelector((state) => state);
  const dispatch = useDispatch();
  const PF = process.env.REACT_APP_IMAGE_URL;

  const [theme, setTheme] = useState("light");

  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  useEffect(() => {
    dispatch(applyTheme(theme));
    document.body.className = theme;
  }, [theme, dispatch]);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="top">
      <div className="topLeft">
        <img src={logo} alt="SOLAR1" />
      </div>
      <div className="topCenter">
        <ul className="topList">
          <li className="topListItem">
            <Link to="/" className="link">
              HOME
            </Link>
          </li>
          <li className="topListItem">
            <Link to="/projects" className="link">
              PROJECTS
            </Link>
          </li>
          {data.auth.user ? (
            <>
              <li className="topListItem">
                <Link to="/write" className="link">
                  WRITE
                </Link>
              </li>
              <li className="topListItem">
                <Link to="/utils" className="link">
                  UTILITIES
                </Link>
              </li>
            </>
          ) : (
            <></>
          )}
          <li className="topListItem">
            <i className="fa-brands fa-square-instagram"></i>
          </li>
        </ul>
      </div>
      <div className="topRight">
        <p className="darkModeLabel">Dark Mode:</p>
        <Switch
          className="toggleswtch"
          onChange={toggleTheme}
          checked={theme === "dark" ? true : false}
        />
        {data.auth.user ? (
          <ul className="topList">
            <Link to="/settings">
              <img
                className="topImg"
                src={PF + data.auth.user.profile_pic}
                alt=""
              />
            </Link>
            <li className="topListItem" onClick={handleLogout}>
              {data.auth.user && "LOGOUT"}
            </li>
          </ul>
        ) : (
          <ul className="topList">
            <li className="topListItem">
              <Link className="link" to="/login">
                LOGIN
              </Link>
            </li>
          </ul>
        )}
      </div>{" "}
    </div>
  );
}
