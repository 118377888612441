export const Actions = {
  SET_THEME: "SET_THEME",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAIL: "REGISTER_FAIL",
  LOGIN_START: "LOGIN_START",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  REFRESH_START: "REFRESH_START",
  REFRESH_SUCCESS: "REFRESH_SUCCESS",
  REFRESH_FAIL: "REFRESH_FAIL",
  UPDATE_START: "UPDATE_START",
  UPDATE_SUCCESS: "UPDATE_SUCCESS",
  UPDATE_FAILURE: "UPDATE_FAILURE",
  LOGOUT: "LOGOUT",
  ADD_POST: "ADD_POST",
  GET_POSTS: "GET_POSTS",
  GET_POST: "GET_POST",
  DELETE_POST: "DELETE_POST",
  UPDATE_POST: "UPDATE_POST",
  SET_MESSAGE: "SET_MESSAGE",
  CLEAR_MESSAGE: "CLEAR_MESSAGE",
  GET_CATS: "GET_CATS",
  FETCH_POWER_DATA: "FETCH_POWER_DATA",
};
