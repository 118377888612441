import { Actions } from "./types";
//import Auth from "../services/Auth";
import AuthApiService from "../services/AuthApiService";

export const LoginStart = () => ({
  type: "LOGIN_START",
});

export const LoginFailure = () => ({
  type: "LOGIN_FAILURE",
});

export const Logout = () => ({
  type: "LOGOUT",
});

export const UpdateStart = () => ({
  type: "UPDATE_START",
});

export const UpdateSuccess = (user) => ({
  type: "UPDATE_SUCCESS",
  payload: user,
});

export const UpdateFailure = () => ({
  type: "UPDATE_FAILURE",
});

export const login = (username, password) => (dispatch) => {
  return AuthApiService.login(username, password).then(
    (data) => {
      //console.log(data);
      dispatch({
        type: Actions.LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.detail) ||
        error.message ||
        error.toString();

      dispatch({
        type: Actions.LOGIN_FAIL,
      });

      dispatch({
        type: Actions.SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const refresh = (user) => (dispatch) => {
  dispatch({
    type: Actions.REFRESH_SUCCESS,
    payload: { user: user },
  });
};

export const logout = () => (dispatch) => {
  dispatch({
    type: Actions.LOGOUT,
  });
};
