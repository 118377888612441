import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCats } from "../../actions/main";
import "./sidebar.css";
import me from "../../images/me.png";
import SocialFollow from "../socialfollow/SocialFollow";

export default function Sidebar() {
  let data = useSelector((state) => state.main);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCats());
  }, [dispatch]);

  return (
    <div className="sidebar">
      <div className="sidebarItem">
        <span className="sidebarTitle">ABOUT ME</span>
        <img src={me} alt="" />
        <p>Hacking, making and breaking things since the '80s.</p>
      </div>
      <div className="sidebarItem">
        <span className="sidebarTitle">CATEGORIES</span>
        <ul className="sidebarList">
          {data.categories.map((c, idx) => (
            <Link key={idx} to={`/?cat=${c.name}`} className="link">
              <li className="sidebarListItem">{c.name}</li>
            </Link>
          ))}
        </ul>
      </div>
      <div className="sidebarItem">
        <span className="sidebarTitle">FOLLOW ME</span>
        <SocialFollow />
      </div>
    </div>
  );
}
