import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { deletePost, getPost, updatePost } from "../../actions/main";
import "./singlepost.css";

export default function SinglePost() {
  let data = useSelector((state) => state);
  const dispatch = useDispatch();
  const location = useLocation();
  const [desc, setDesc] = useState(data.main.post.desc);
  const [title, setTitle] = useState(data.main.post.title);
  const path = location.pathname.split("/")[2];

  const [updateMode, setUpdateMode] = useState(false);

  useEffect(() => {
    dispatch(getPost(path));
  }, [dispatch, path]);

  const handleDelete = () => {
    try {
      dispatch(deletePost(data.main.post.id, data.auth.user.username));
      window.location.replace("/");
    } catch (err) {}
  };

  const handleUpdate = () => {
    try {
      dispatch(
        updatePost(data.main.post.id, {
          username: data.auth.user.username,
          title,
          desc,
          category: data.main.post.category,
        })
      );
      setUpdateMode(false);
    } catch (err) {}
  };

  return (
    <div className="singlePost">
      <div className="singlePostWrapper">
        {data.main.post.image && (
          <img src={data.main.post.image} alt="" className="singlePostImg" />
        )}
        {updateMode ? (
          <input
            type="text"
            value={title}
            className="singlePostTitleInput"
            autoFocus
            onChange={(e) => setTitle(e.target.value)}
          />
        ) : (
          <h1 className="singlePostTitle">
            {title}
            {data.main.post.username === data.auth.user.username && (
              <div className="singlePostEdit">
                <i
                  className="singlePostIcon far fa-edit"
                  onClick={() => setUpdateMode(true)}
                ></i>
                <i
                  className="singlePostIcon far fa-trash-alt"
                  onClick={handleDelete}
                ></i>
              </div>
            )}
          </h1>
        )}
        <div className="singlePostInfo">
          <span className="singlePostAuthor">
            Author:
            <Link to={`/?user=${data.main.post.username}`} className="link">
              <b> {data.main.post.username}</b>
            </Link>
          </span>
          <span className="singlePostDate">
            {new Date(data.main.post.created_at).toDateString()}
          </span>
        </div>
        {updateMode ? (
          <textarea
            className="singlePostDescInput"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
        ) : (
          <p className="singlePostDesc">{desc}</p>
        )}
        {updateMode && (
          <button className="singlePostButton" onClick={handleUpdate}>
            Update
          </button>
        )}
      </div>
    </div>
  );
}
