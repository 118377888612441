import React from "react";
import Topbar from "./components/topbar/TopBar";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Settings from "./pages/settings/Settings";
import Single from "./pages/single/Single";
import Write from "./pages/write/Write";
import Utils from "./pages/utils/Utils";
import Fronius from "./pages/fronius/Fronius";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { connect } from "react-redux";

function App() {
  let data = useSelector((state) => state);
  return (
    <Router>
      <Topbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/login" element={data.auth.user ? <Home /> : <Login />} />
        <Route path="/write" element={data.auth.user ? <Write /> : <Login />} />
        <Route path="/utils" element={data.auth.user ? <Utils /> : <Login />} />

        <Route
          path="/fronius"
          element={data.auth.user ? <Fronius /> : <Login />}
        />
        <Route
          path="/settings"
          element={data.auth.user ? <Settings /> : <Login />}
        />
        <Route path="/post/:postId" element={<Single />} />
      </Routes>
    </Router>
  );
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(App);
